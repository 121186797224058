import {Button, Container} from "react-bootstrap";
import {PageHeader} from "../menu/PageHeader";
import {AgGridReact} from "ag-grid-react";
import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {
  CONF_LABEL_REG_CLOSED_CONTENT,
  MODE_CREATE,
  MODE_EDIT,
  MODE_LIST,
  MODE_VIEW,
  PERMISSION_CONF_ADMIN,
  PERMISSION_CONF_ORGANIZER,
  PERMISSION_STAFF,
  REG_FILTER_DEFAULT_STATE
} from "../constants";
import {getRegs} from "../api/RegApi";
import {RegView} from "./RegView";
import {getRegStatusDisplay, userCanCreateReg} from "./regUtils";
import {hasPermission} from "../api/UserApi";
import {naturalSort, toolTipRenderer} from "../utils/gridUtils";
import {RegEdit} from "./RegEdit";
import {Register} from "./Register";
import {UsacmContext} from "../App";
import {TABLE_FILTER_MODE_REG, TableFilter} from "../shared/TableFilter";
import {deepCopy, getConfLabel} from "../utils/usacmUtils";


export function RegList({couponId, onClose}) {
  const context = useContext(UsacmContext);
  const [navChanged,] = context.navigationChanged;
  const [conf,] = context.conference;
  const [regList, setRegList] = useState([]);
  const [summaryFieldNames, setSummaryFieldNames] = useState([]);
  const [mode, setMode] = useState(MODE_LIST);
  const [regId, setRegId] = useState(null);
  const gridRef = useRef();
  const [filters, setFilters] = useState(deepCopy(REG_FILTER_DEFAULT_STATE));
  const [statusCounts, setStatusCounts] = useState({}); // contains {'pending':3, 'paid':0,...}
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);
  const isConfOrg = hasPermission(PERMISSION_CONF_ORGANIZER);
  const isStaffOrAdminOrOrg = isStaffOrAdmin || isConfOrg;
  const filteredRegList = filterRegList(regList);
  const hasFewRows = (filteredRegList || []).length < 12;
  const regCount = (regList || []).length;
  const showingTable = onClose || (((regCount > 1) || isStaffOrAdminOrOrg) && (mode === MODE_LIST));
  const canCreateReg = userCanCreateReg(conf);
  const pastRegEnd = !!conf?.deadlines?.past_reg_end;
  const regEndHtmlContent = getConfLabel(conf, CONF_LABEL_REG_CLOSED_CONTENT);
  const canCreateRegForOtherUsers = conf?.reg_allow_assign;
  const regUseAttendance = !!conf?.reg_use_attendance;

  // When the navUrl changes it means the left menu was clicked - we want to reset the component state
  // NOTE: this should be defined before the other useEffect so it runs first and can be overridden
  useEffect(() => {
    setMode(MODE_LIST);
    setRegId(null);
  }, [navChanged]);

  // On component load (this happens twice in dev mode)
  useEffect(() => {
    loadRegList();
  }, []);

  // Reload all the user data (after we know something changed)
  function loadRegList(showListAfterLoad = false) {
    getRegs(couponId, (code, data, errors) => {
      if (code === 200) {
        const newRegList = data['reg'];
        setRegList(newRegList);
        setSummaryFieldNames(data['summary_field_names']);
        setStatusCounts(calcRegStatusCounts(data['reg']));
        // If there are no regs for this uer - then let's start creating one (no point in making them clicking the button)
        if (!onClose && (newRegList.length === 0) && !pastRegEnd) {
          setMode(MODE_CREATE);
        }
        if (showListAfterLoad) {
          // Need a timeout here so that the newRegList is set before the table is rendered.
          setTimeout(() => showList(), 0);
        }
      }
    });
  }

  function createReg() {
    setMode(MODE_CREATE);
    setRegId(null);
  }

  function showList() {
    setMode(MODE_LIST);
    setRegId(null);
  }

  function showView(newRegId) {
    setMode(MODE_VIEW);
    setRegId(newRegId);
  }

  function showEdit(newRegId) {
    setMode(MODE_EDIT);
    setRegId(newRegId);
  }

  function finishedRegistration() {
    loadRegList(true);
  }

  /**
   * Calculate the status counts from the regList
   * Format is : {'pending':3, 'paid':0,...}
   */
  function calcRegStatusCounts(newRegList) {
    const newCounts = {};
    for (const reg of newRegList) {
      const key = reg.reg_status;
      newCounts[key] = (newCounts[key] || 0) + 1;
    }
    newCounts['all'] = newRegList.length;
    return newCounts;
  }

  function filterRegList(newRegList) {
    if (!newRegList) {
      return [];
    }
    // If all is selected then we return the entire list
    if (filters.find(f => f.key === 'all' && f.showing)) {
      return newRegList;
    }
    // Get the active filters
    const showingFilters = filters.filter(f => f.showing);
    return newRegList.filter(reg => {
      for (const filter of showingFilters) {
        if (reg.reg_status === filter.key) {
          return true; // return from newRegList.filter
        }
      } // for filter
      return false; // return from newRegList.filter
    });
  }

  // Column setup
  const colDefs = [
    {field: 'id', headerName: 'ID', width: 80, minWidth: 80},
    {field: 'create_date', headerName: 'Date', width: 110, minWidth: 110},
    ...((isStaffOrAdminOrOrg || canCreateRegForOtherUsers) ? [{
      headerName: 'User',
      minWidth: 150,
      flex: 5,
      filter: true,
      valueGetter: params => params.data.assigned_user_name + ' (' + params.data.assigned_user_email + ')',
      cellRenderer: toolTipRenderer,
      comparator: naturalSort,
    }] : []),
    {
      headerName: 'Status',
      headerTooltip: 'Status',
      flex: 3,
      minWidth: 100,
      filter: true,
      valueGetter: params => getRegStatusDisplay(params.data.reg_status),
    },
    ...(regUseAttendance ? [{
      headerName: 'Attended',
      headerTooltip: 'Attended',
      flex: 2,
      minWidth: 45,
      filter: true,
      valueGetter: params => params.data.attended ? 'Yes' : 'No',
    }] : []),
    ...(summaryFieldNames.map(name => (
      {
        field: name,
        headerName: name,
        flex: 5,
        minWidth: 120,
        filter: true,
        cellRenderer: toolTipRenderer,
        comparator: naturalSort,
      }))),

    ...(onClose ? [] : [{
      headerName: 'Action',
      sortable: false,
      filter: false,
      maxWidth: 150,
      minWidth: 150,
      width: 150,
      cellRenderer: params => {
        const rowRegId = params.data.id;
        return <Fragment>
          <Button type="button" onClick={() => showView(rowRegId)} size="sm" className="me-2">View</Button>
          {isStaffOrAdmin &&
            <Button type="button" onClick={() => showEdit(rowRegId)} size="sm" className="me-2">Edit</Button>
          }
        </Fragment>;
      }
    }]),
  ];

  const defaultColDefs = {
    sortable: true,
    filter: false,
    resizable: true,
  }

  return (
    <div>

      {!onClose &&
        <PageHeader pageTitle="Registration"/>
      }

      {pastRegEnd && !onClose &&
        <div className='mt-3 ms-3' dangerouslySetInnerHTML={{__html: regEndHtmlContent}}/>
      }

      <Container fluid className="usacm-container-wide" style={{display: showingTable ? 'block' : 'none'}}>
        <TableFilter conf={conf}
                     mode={TABLE_FILTER_MODE_REG}
                     showFilters={isStaffOrAdminOrOrg && !onClose}
                     filters={filters}
                     setFilters={setFilters}
                     showDownload={true}
                     downloadUrl={'reg/list/excel/'}
                     downloadFilename={'registration_list.xlsx'}
                     gridApi={gridRef?.current?.api}
                     statusCounts={statusCounts}
                     onSearchChanged={null}
                     showOtherButton={false}
                     otherButtonText=''
                     otherButtonOnclick={null}
        />
        <div className="ag-theme-alpine" style={{width: '100%', ...(hasFewRows ? {} : {height: '700px'})}}>
          <AgGridReact
            ref={gridRef}
            rowData={filteredRegList}
            columnDefs={colDefs}
            defaultColDef={defaultColDefs}
            domLayout={hasFewRows ? "autoHeight" : "normal"}
            gridOptions={{enableCellTextSelection: true, ensureDomOrder: true}} // enable cell selection
            getRowId={(params) => params.data.id} // prevent re-rendering the whole table when data changes
          />
        </div>
      </Container>

      {mode === MODE_VIEW &&
        <RegView regId={regId} onClose={() => showList()} onCreate={null}/>
      }

      {mode === MODE_EDIT &&
        <RegEdit regId={regId} onFinished={finishedRegistration}/>
      }

      {mode === MODE_CREATE &&
        <Register preview={false} regId={null} onFinished={finishedRegistration} showCheckout={true}/>
      }

      {(regCount === 1) && (mode === MODE_LIST) && !onClose && !isStaffOrAdminOrOrg &&
        <RegView regId={regList[0].id} onClose={null} onCreate={() => createReg()}/>
      }

      {canCreateReg && (mode === MODE_LIST) && !onClose && (regCount !== 1) &&
        <div className={'usacm-button-row mb-3' + (showingTable ? '' : ' mt-3')}>
          <Button onClick={() => createReg()}>Add Registration</Button>
        </div>
      }

      {onClose &&
        <div className={'usacm-button-row mb-3'}>
          <Button variant="secondary" onClick={() => onClose()}>Back</Button>
        </div>
      }

    </div>
  );

}
