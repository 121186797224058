import {Button, Col, Container, FloatingLabel, Form, Row} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {UsacmContext} from "../App";
import {fieldHasErrors, getErrorMessageForField} from "../utils/formUtils";
import {getCurrentConf, setPageTitle, updateConfGeneral} from "../api/ConfApi";
import {showSuccessToast} from "../utils/usacmUtils";
import {hasPermission} from "../api/UserApi";
import {PERMISSION_CONF_ADMIN, PERMISSION_STAFF} from "../constants";

export function ConfSettingsGeneral() {
  const {conference} = useContext(UsacmContext);
  const [conf, setConf] = conference;
  const [shortName, setShortName] = useState('');
  const [longName, setLongName] = useState('');
  const [locationCountry, setLocationCountry] = useState('');
  const [locationState, setLocationState] = useState('');
  const [locationCity, setLocationCity] = useState('');
  const [location, setLocation] = useState('');
  const [confStart, setConfStart] = useState('');
  const [confEnd, setConfEnd] = useState('');
  const [fromEmail, setFromEmail] = useState('');
  const [useRegistration, setUseRegistration] = useState(true);
  const [errors, setErrors] = useState([]);
  const isStaffOrAdmin = hasPermission(PERMISSION_STAFF) || hasPermission(PERMISSION_CONF_ADMIN);

  useEffect(() => {
    if (conf) {
      setShortName(conf.short_name || '');
      setLongName(conf.long_name || '');
      setLocationCountry(conf.location_country || '');
      setLocationState(conf.location_state || '');
      setLocationCity(conf.location_city || '');
      setLocation(conf.location || '');
      setConfStart(conf.conf_start || '');
      setConfEnd(conf.conf_end || '')
      setFromEmail(conf.from_email || '');
      setUseRegistration(conf.use_registration);
    }
  }, [conf]);

  function callUpdateConf(event) {
    event.preventDefault();
    updateConfGeneral(conf?.host, shortName, longName, locationCountry, locationState, locationCity, location,
      confStart, confEnd, fromEmail, useRegistration, (status, data, newErrors) => {
        if (status === 200) {
          setErrors([]);
          showSuccessToast("Conference settings saved");
          getCurrentConf((code, data, errors) => {
            if (code === 200) {
              setConf(data);
              setPageTitle(data.short_name)
            } else {
              console.warn('Error loading conf in settings', errors);
            }
          });
        } else {
          setErrors(newErrors);
        }
      });
  }

  return (
    <Form onSubmit={callUpdateConf}>
      <Container fluid className="usacm-container-narrow">
        <Row className="usacm-fake-input">
          Host is {conf?.host}
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="shortname">
              <FloatingLabel controlId="shortname" label="Short Name">
                <Form.Control type="text"
                              placeholder="Short Name"
                              name="short_name"
                              value={shortName}
                              onChange={e => setShortName(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'short_name')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'short_name')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="longname">
              <FloatingLabel controlId="longname" label="Long Name">
                <Form.Control type="text"
                              placeholder="Long Name"
                              name="long_name"
                              value={longName}
                              onChange={e => setLongName(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'long_name')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'long_name')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="location_country">
              <FloatingLabel controlId="location_country" label="Location - Country">
                <Form.Control type="text"
                              name="location_country"
                              value={locationCountry}
                              onChange={e => setLocationCountry(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'location_country')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'location_country')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="location_state">
              <FloatingLabel controlId="location_state" label="Location - State">
                <Form.Control type="text"
                              name="location_state"
                              value={locationState}
                              onChange={e => setLocationState(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'location_state')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'location_state')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="location_city">
              <FloatingLabel controlId="location_city" label="Location - City">
                <Form.Control type="text"
                              name="location_city"
                              value={locationCity}
                              onChange={e => setLocationCity(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'location_city')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'location_city')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="location">
              <FloatingLabel controlId="location" label="Location">
                <Form.Control type="text"
                              name="location"
                              value={location}
                              onChange={e => setLocation(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'location')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'location')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <div className="d-flex">
              <Form.Group controlId="conf_start" className="flex-grow-1 me-2">
                <FloatingLabel controlId="conf_start" label="Start Date">
                  <Form.Control type="date"
                                placeholder="Start Date"
                                name="conf_start"
                                value={confStart}
                                onChange={e => setConfStart(e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, 'conf_start')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'conf_start')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
              <Form.Group controlId="conf_end" className="flex-grow-1 ms-2">
                <FloatingLabel controlId="conf_end" label="End Date">
                  <Form.Control type="date"
                                placeholder="End Date"
                                name="conf_end"
                                value={confEnd}
                                onChange={e => setConfEnd(e.target.value)}
                                readOnly={!isStaffOrAdmin}
                                isInvalid={fieldHasErrors(errors, 'conf_end')}/>
                  <Form.Control.Feedback type="invalid">
                    {getErrorMessageForField(errors, 'conf_end')}
                  </Form.Control.Feedback>
                </FloatingLabel>
              </Form.Group>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group controlId="from_email">
              <FloatingLabel controlId="from_email" label="From Email">
                <Form.Control type="text"
                              name="from_email"
                              value={fromEmail}
                              onChange={e => setFromEmail(e.target.value)}
                              readOnly={!isStaffOrAdmin}
                              isInvalid={fieldHasErrors(errors, 'from_email')}/>
                <Form.Control.Feedback type="invalid">
                  {getErrorMessageForField(errors, 'from_email')}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
            NOTE: This email must have Domain Authentication in Sendgrid
          </Col>
        </Row>

        <Col className="mb-3">
          <Form.Check
            type="checkbox"
            label="Use Registration"
            id="use_registration"
            checked={useRegistration}
            onChange={e => isStaffOrAdmin && setUseRegistration(e.target.checked)}
            isInvalid={fieldHasErrors(errors, 'use_registration')}
          />
          <div className="usacm-invalid-feedback">
            {getErrorMessageForField(errors, 'use_registration')}
          </div>
        </Col>

        {fieldHasErrors(errors, '') &&
          <Row>
            <Col className="text-center mb-3 usacm-error-message">
              {getErrorMessageForField(errors, '')}
            </Col>
          </Row>
        }

        <Row>
          <Col className="text-center mb-3">
            {isStaffOrAdmin &&
              <Button type="submit" className="ms-3 me-3">Update Conference</Button>
            }
          </Col>
        </Row>

      </Container>
    </Form>
  );

}
