import React, {useEffect, useState} from "react";
import {getTemplateFields} from "../api/RegApi";
import {RegFieldEdit} from "./RegFieldEdit";
import {RegFieldSectionEdit} from "./RegFieldSectionEdit";

export function RegFields() {
  const [fields, setFields] = useState([]);
  const [selectedFieldData, setSelectedFieldData] = useState(null);

  function loadTemplateFields() {
    getTemplateFields((status, data, newErrors) => {
      if (status === 200) {
        setFields(data);
      }
    });
  }

  useEffect(() => {
    loadTemplateFields();
  }, []);

  function doneEditing() {
    loadTemplateFields();
    setSelectedFieldData(null);
  }

  return (
      <div>
        {!selectedFieldData &&
          <RegFieldSectionEdit parentFieldId={null}
                               parentFieldName=""
                               fields={fields}
                               setSelectedFieldData={setSelectedFieldData}
                               loadTemplateFields={loadTemplateFields} />
        }

        {selectedFieldData &&
        <RegFieldEdit origFieldData={selectedFieldData} onClose={doneEditing}/>
        }
      </div>
  );

}
